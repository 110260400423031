<template>
  <v-row v-if="!isLoading">
    <!-- kitchen sink -->
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.flights')} / TTI Zenith`">
        <v-row v-if="isActiveAPITTI">
          <v-spacer></v-spacer>
          <v-col
            v-if="
              permisos.includes('nomenclators_tti:create') ||
                permisos.includes('nomenclators_tti:edit') ||
                permisos.includes('*')
            "
            cols="12"
            md="2"
            class="mr-10"
          >
            <v-btn
              color="primary"
              class="mb-4"
              :loading="loading"
              @click="sincronizarAuto()"
            >
              <v-icon>mdi-autorenew</v-icon>
              <span>{{ $t('btn.sincronizar') }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <TTIList v-if="mostrarList" />
        <v-row v-else>
          <v-col
            cols="12"
            md="6"
            offset-md="2"
          >
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import TTIList from './tablas/TTIList.vue'

export default {
  components: {
    AppCardCode,
    TTIList,
  },
  data() {
    return {
      isLoading: true,
      loading: false,
      mostrarList: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      isActiveAPITTI: false,
    }
  },
  created() {
    this.activeApi()
  },
  methods: {
    activeApi() {
      this.axios
        .post(
          'api_extern',
          { type: 'gds' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          const result = response.data.data
          result.forEach(element => {
            if (element.slug === 'tti-zenith' && element.active) {
              this.isActiveAPITTI = true
            }
          })
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false
        })
    },
    sincronizarAuto() {
      this.loading = true
      this.mostrarList = false
      this.axios
        .post(
          'nom_extern_api/sync',
          { action: 'sync' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success(this.$t('msg.dataSync'))
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.loading = false
          this.mostrarList = true
        })
    },
  },
}
</script>
