<template>
  <div>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row class="mt-2">
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="4"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ code | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.code') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.description') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>{{ iten.data.Code }}</td>
            <td>{{ iten.data.Label }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    code: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    nameAPI: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  created() {
    this.load()
    this.loadAll()
  },
  methods: {
    load() {
      const json = {
        api_name: this.nameAPI,
        value_code_name: this.code,
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.search,
      }
      this.axios
        .post('nom_extern_api/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    loadAll() {
      const json = {
        api_name: this.nameAPI,
        value_code_name: this.code,
        per_page: 100000,
      }
      this.axios
        .post('nom_extern_api/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
