<template>
  <div v-if="!isLoading">
    <v-tabs
      v-model="pos"
      icons-and-text
    >
      <v-tab
        v-for="(code, ind) in codesList"
        :key="ind"
      >
        {{ code }}
      </v-tab>
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="pos">
          <v-tab-item
            v-for="(code, ind) in codesList"
            :key="ind"
          >
            <Codes
              :code="code"
              :name-a-p-i="'tti'"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'
import Codes from '../utils/tii/Codes.vue'

export default {
  components: {
    Codes,
  },
  data() {
    return {
      pos: 0,
      codesList: [],
      isLoading: true,
      search: '',
      items: [],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      itemsPerPageResult: state => state.app.itemsPerPageResult,
      searchResult: state => state.app.searchResult,
    }),
  },
  created() {
    this.profile()
    this.loadCodes()

    // this.load()
    // this.loadAll()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    loadCodes() {
      const json = {
        api_name: 'tti',
      }
      this.axios
        .post('nom_extern_api/list-codes', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === true) {
            this.codesList = res.data.data
          }
        })
    },
    loadAll() {
      this.axios
        .get('airplane?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
